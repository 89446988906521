import axios from 'axios';
import chroma from 'chroma-js';
import { getAvailableRoutes} from './RouteUtils';

const API_KEY='B3UnAYQeUuetmQOwU48vmQ==';

const mutedTextColor = "#5c6873";
const colorStylesForSelectElem = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      const color = chroma(mutedTextColor);
      return {
        ...styles,
        backgroundColor: isSelected
          ? mutedTextColor
          : isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : mutedTextColor,  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? mutedTextColor : color.alpha(0.3).css()),
        },
      };
    },
    singleValue: styles => {
        return { 
            ...styles,
            color: mutedTextColor
        }
    }
}

function appendAPIKeyToURL(url){
    url.includes('?') ? url += '&' : url += '?';
    return url + 'api_key=' + API_KEY;
}

function licenseContainsBDE(){
    var licensedModules = JSON.parse(localStorage.getItem('licensedModules'));
    if(licensedModules){
        return licensedModules.BDE.BDEBasic && licensedModules.WebClient.BDE;
    }
    return false
}

function licenseContainsPZEWorkflow(){
    var licensedModules = JSON.parse(localStorage.getItem('licensedModules'));
    if(licensedModules){
      return licensedModules.PZE.PZEWorkflow;
    }
    return false;
}

function getProductName(){
    var name = "PZE ";
    if(licenseContainsBDE() === true){
        name = "PZBDE " 
    }
    return name;
}

function getFullProductName(){
    var name = "PZE ";
    if(licenseContainsBDE() === true){
        name = "PZBDE " 
    }
    name += "WebClient 3.0"
    return name;
}

function getUserStartPage(userInfo = JSON.parse(localStorage.getItem('userInfo'))){
  const webClientConfiguration = JSON.parse(localStorage.getItem('WebClientConfiguration'));

  let startPage = "";
  if (userInfo && !userInfo.UserConfiguration.DefaultStartPageUsage){
    startPage = userInfo.UserConfiguration.StartPage;
      
    // set up start page is not in availablePages, then use the defaultStartPage for the TerminalMode
    const availablePaths = getAvailableRoutes().map( route => route.path.slice(1));
    if(webClientConfiguration.PZE.TerminalMode && !availablePaths.includes(startPage)){
      startPage = webClientConfiguration.PZE.DefaultStartPage;
    }
  }
  else {
    if(webClientConfiguration){
      startPage = webClientConfiguration.PZE.DefaultStartPage;
    }
  }
  
  return startPage.length > 0 ? startPage.charAt(0).toLowerCase() + startPage.slice(1): "";
}

function redirectUserToStartPage(props){
  const startPage = getUserStartPage();

  let redirectTo ='/' + startPage;
  if(props.location.state && props.location.state.from.pathname !== "/"){
    redirectTo = props.location.state.from.pathname;  
  }
  
  props.history.push(redirectTo);
}

function getUserClientInterval(){
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let clientLogoutTimeSpan = "";
  if (userInfo && !userInfo.UserConfiguration.DefaultClientAutoLogoutUsage){
    clientLogoutTimeSpan = userInfo.UserConfiguration.ClientAutoLogout;
  }
  else{
    const webClientConfiguration = JSON.parse(localStorage.getItem('WebClientConfiguration'));
    if(webClientConfiguration){
      clientLogoutTimeSpan = webClientConfiguration.PZE.DefaultClientAutoLogout;
    }
  }

  return clientLogoutTimeSpan;
}

async function getWebClientConfiguration(){
  const url = window['runConfig'].RESTAPI_URL_PZE + 'WebClientConfiguration/WebClientConfiguration';
  
  try{      
    const response = await axios.get(appendAPIKeyToURL(url)).then( (res) =>{
      return res;
    });
    if(response.status === 200){
      const config = response.data;
      localStorage.setItem('WebClientConfiguration', JSON.stringify(config));
    }  
  }
  catch(error) {
    console.log("Error in WebClientConfiguration/WebClientConfiguration: ", error.message);      
  }
  
}

async function callUserLoginState(){
  let userIsLoggedIn = null;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if(userInfo){
    const url = window['runConfig'].RESTAPI_URL_PZE + "UserAuthentication/SetUserAlive";

    const config = {
      timeout: 5000
    };

    const requestData = {
      StaffID: userInfo.StaffID
    }

    try{
      const response = await axios.post(appendAPIKeyToURL(url), requestData, config).then( (res) =>{
        return res;
      });
      if(response.status === 200){
        userIsLoggedIn = response.data.userIsLoggedIn;
        localStorage.setItem('userIsLoggedIn', JSON.stringify(userIsLoggedIn));
      }
    }
    catch(error){
      if(error.request){ // timeout reached
        console.log("Timeout")
      }
    }
  }
  return userIsLoggedIn;
}

async function getNumberOfUnreadRequests(){
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let numberOfUnreadRequests = null;

  if(userInfo){
    const url = window['runConfig'].RESTAPI_URL_PZE + 'WagePaymentRequest/GetNumberOfUnreadRequestsByStaffWmdID?staffWmdID=' + userInfo.StaffWorkingModelID.toString();
  
    try{      
      const response = await axios.get(appendAPIKeyToURL(url)).then((res)=>{
        return res;
      });
      if(response.status === 200 ){
        numberOfUnreadRequests = response.data["TotalNumberOfUnreadRequests"];
      }
    }
    catch(error) {
      console.log("Error in WagePaymentRequest/GetNumberOfUnreadRequestsByStaffWmdID: ", error.message);
    }
    finally{
      return numberOfUnreadRequests;
    }
  }
  else{
    return numberOfUnreadRequests;
  }
  
}

function handleStorageElementsForLogout() {
    localStorage.setItem("userState", "inactive");
    
    localStorage.removeItem("userInfo");
    localStorage.removeItem("loginMode");
    localStorage.removeItem("TimeTypes");
    localStorage.removeItem("TimeTypes_IDs");
    //localStorage.removeItem("TimeTypesForAbsenceWorkflow");
    localStorage.removeItem("FlextimeAccount");
    localStorage.removeItem("MonthAccountingInfo");
    localStorage.removeItem("TimeTypesForAbsenceCalendar");
    localStorage.removeItem("TimeTypes_ActivityEvents");
    localStorage.removeItem("componentPath");
    localStorage.removeItem("pwdAlreadyChanged");
    localStorage.removeItem("userIsLoggedIn");
    //localStorage.removeItem('unadoptedWebBookingsForStaffWmdID'); // TODO ?
    localStorage.removeItem('latestDbBookingForStaffWmdID');
    localStorage.removeItem('VacationData');
    localStorage.removeItem('HolidayBookingsUnit');
    localStorage.removeItem('lastActive');

    localStorage.removeItem('UnloadTime');
    localStorage.removeItem('logoutRequired');

    if(licenseContainsBDE()){
      localStorage.removeItem("WorkOrders");
      localStorage.removeItem("ValidActivities");
      localStorage.removeItem("StatusesForSequences");
      localStorage.removeItem("Quantities");
      //localStorage.removeItem("latestEventOrderBookingForStaffWmdID");     
    }
}

export { 
    appendAPIKeyToURL,
    colorStylesForSelectElem,
    licenseContainsBDE,
    licenseContainsPZEWorkflow,
    getProductName,
    getFullProductName,
    getUserStartPage,
    redirectUserToStartPage,
    getUserClientInterval,
    getWebClientConfiguration,
    callUserLoginState,
    getNumberOfUnreadRequests,
    handleStorageElementsForLogout
}