import React, { Component } from 'react';
import { 
  BrowserRouter, 
  Switch
 } from 'react-router-dom';
//import logo from './logo.svg';
import './App.scss';
import {PrivateRoute, LoginRoute} from './utils/RouteUtils';
import { callUserLoginState, appendAPIKeyToURL, getWebClientConfiguration } from './utils/CommonUtils';
import { 
  getOpenComponents,
  getClosedComponents,
  getNewComponentNameInLocalStorage,
  updateComponentEntryInLocalStorage,
  removeClosedComponentsFromStorage,
  getComponentsFromLocalStorage,
  getLatestClosingTime
} from './utils/StorageUtils';
import { loading } from './utils/Spinners';

import i18n from './i18n';
import axios from 'axios';
import moment from 'moment';

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
//const LockScreen = React.lazy(() => import('./views/Pages/LockScreen'));

class App extends Component {
  constructor(props) {
    super(props);

    this.handleLoad = this.handleLoad.bind(this);
    this.handleUnload = this.handleUnload.bind(this);
    
    this.updateLoginState = this.updateLoginState.bind(this);
    this.updateClientLogoutInterval = this.updateClientLogoutInterval.bind(this);
    const detectedLang = localStorage.getItem("i18nextLng") ? localStorage.getItem("i18nextLng"): navigator.language;
    const compName = getNewComponentNameInLocalStorage();

    this.getClientLogoutInterval = this.getClientLogoutInterval.bind(this);

    this.state = {
      selectedLang: detectedLang,
      userIsLoggedIn: null,
      componentName: compName,

      clientLogoutInterval: ""
    };
  }

  async componentDidMount(){
    window.addEventListener("load", () => this.handleLoad());
    window.addEventListener("unload", () => this.handleUnload());

    await this.getLicenseInformation();
    await getWebClientConfiguration();
    this.getClientLogoutInterval();
  }

  componentWillUnmount(){
    window.removeEventListener("load", this.handleLoad);
    window.removeEventListener("unload", this.handleUnload);
  }

  async handleLoad(){
    const allComponents = getComponentsFromLocalStorage();
    const openedComponents = getOpenComponents();
    let closedComponents = getClosedComponents();

    const userState = localStorage.getItem('userState');
    
    const loadTime = moment();
    
    if(allComponents !== null){
      const latestUnloadingTime = getLatestClosingTime(closedComponents);
      
      removeClosedComponentsFromStorage(closedComponents)
      // Get new list of now closed tabs
      closedComponents = getClosedComponents();

      if(openedComponents.length === 0 && closedComponents.length === 0 && userState === "active"){
        const timeDiff = loadTime.diff(latestUnloadingTime);
        
        if(timeDiff > 650 && !window.performance.getEntriesByType("navigation").map(nav => nav.type).includes('reload')){
          localStorage.setItem("logoutRequired", JSON.stringify(true));
        }
      } 
      
      localStorage.removeItem('UnloadTime');
    }
    
    updateComponentEntryInLocalStorage(this.state.componentName, false)   
  }

  handleUnload(){
    updateComponentEntryInLocalStorage(this.state.componentName, true)
  }

  changeLang(lng){
    i18n.changeLanguage(lng);
    this.setState({
      selectedLang: lng
    });
  };

  async updateLoginState(){
    const userIsLoggedIn = await callUserLoginState();
    this.setState({
      userIsLoggedIn: userIsLoggedIn
    });
  }

  async getLicenseInformation(){
    let url = window['runConfig'].RESTAPI_URL_PZE + 'License/GetLicenseInformationForWebClient';
    
    try{      
      const response = await axios.get(appendAPIKeyToURL(url)).then( (res) =>{
        return res;
      });
      if(response.status === 200){
        const modules = response.data["licensedModules"];  
        localStorage.setItem('licensedModules', JSON.stringify(modules));
      }  
    }
    catch(error) {
      console.log("Error in License/GetLicenseInformation: ", error.message);      
    }
  
  }

  getClientLogoutInterval(){
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let clientAutoLogout = "";
    if (userInfo && !userInfo.UserConfiguration.DefaultClientAutoLogoutUsage){
      clientAutoLogout = userInfo.UserConfiguration.ClientAutoLogout;
    }
    else{
      if(JSON.parse(localStorage.getItem('WebClientConfiguration'))){
        clientAutoLogout = JSON.parse(localStorage.getItem('WebClientConfiguration')).PZE.DefaultClientAutoLogout;
      }
    }

    this.setState({
      clientLogoutInterval: clientAutoLogout
    })
  }

  updateClientLogoutInterval(){
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.setState({
      clientLogoutInterval: userInfo.UserConfiguration.ClientAutoLogout
    })
  }

  render() {
    //const { t, i18n } = this.props;
    const actualLanguage = this.state.selectedLang;

    return (
      <BrowserRouter basename="/pzeweb">
          <React.Suspense fallback={loading()}>
            <Switch>
              <LoginRoute 
                exact
                path="/login" 
                name="Login"
                render={props => <Login onChangeClientLogoutInterval={() => this.updateClientLogoutInterval()} {...props} />} 
              />
              <PrivateRoute 
                path="/" 
                name="PZE Webclient 3.0" 
                userIsLoggedIn={this.state.userIsLoggedIn} 
                onChangeLoginState={this.updateLoginState}

                clientLogoutInterval={this.state.clientLogoutInterval}
                
                render={
                  props => <DefaultLayout 
                              lang={actualLanguage} 
                              onChangeLang={(lng) => this.changeLang(lng)} 
                              userIsLoggedIn={this.state.userIsLoggedIn} 
                              onChangeLoginState={() => this.updateLoginState()}

                              clientLogoutInterval={this.state.clientLogoutInterval}
                              onChangeClientLogoutInterval={() => this.updateClientLogoutInterval()}
                              {...props}
                            />
                } 
              />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;