import React from 'react';
//eslint-disable-next-line
import moment from 'moment/min/moment-with-locales';
import { Route, Redirect } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { licenseContainsBDE, licenseContainsPZEWorkflow } from './CommonUtils';

//const Attendances = React.lazy(() => import('./views/Attendances/Attendances'));
//const CalendarWeekHours = React.lazy(() => import('./views/CalendarWeekHours/CalendarWeekHours'));

const PageError = React.lazy(() => import('./../views/Pages/PageError'));
const PasswordChange = React.lazy(() => import('./../views/Pages/PasswordChange'));

const Bookings = React.lazy(() => import('./../views/Bookings/Booking'));
const WorkOrderBookings = React.lazy(() => import('./../views/WorkOrderBookings/WorkOrderBooking'));
const PersonalInfos = React.lazy(() => import('./../views/PersonalInfos/PersonalInfos'));
const Requests = React.lazy(() => import('./../views/Requests/Requests'));

const RoutePermissionMap = {
    "/personalInfos" : "PersonalInformation",
    "/bookings": "WorkingTimeBookings",
    "/workOrderBookings": "WorkOrderBookings",
    "/requests": "AbsenceRequests"
}

function getAvailableRoutes(){
    const configuration = JSON.parse(localStorage.getItem('WebClientConfiguration'));
    
    var routes = [
        //{ path: '/attendances', name: <Trans i18nKey="common:attendances"/>, component: Attendances, exact: true },
        //{ path: '/cwHours', name: <Trans i18nKey="common:cwHours"/>, component: CalendarWeekHours, exact: true },
        { path: '/bookings', name: <Trans i18nKey="common:bookings"/>, component: Bookings , exact: true},
    ];
    if (configuration && !configuration.PZE.TerminalMode){
        routes.push({ path: '/personalInfos', name: <Trans i18nKey="common:personalInfos_short"/>, component: PersonalInfos, exact: true })
        if(licenseContainsPZEWorkflow){
            routes.push({ path: '/requests', name: <Trans i18nKey="common:requests"/>, component: Requests, exact: true })
        }
    }
    
    if (licenseContainsBDE){
        routes.push({ path: '/workOrderBookings', name: <Trans i18nKey="common:workOrderBookings"/>, component: WorkOrderBookings, exact: true })
    }

    return routes;
}

function checkForAvailablePages(userInfo){
    var availablePages = [];
    if(userInfo){
        const webClientRolePermissions = userInfo.WebClientRole.WebClientRolePermissions;
        const availableRoutes = getAvailableRoutes().map(r => r.path);
        
        availableRoutes.forEach( path => {
            switch (path) {
                case "/personalInfos":
                case "/bookings":
                case "/workOrderBookings":
                case "/requests":
                    if(webClientRolePermissions[RoutePermissionMap[path]]){
                        availablePages.push(path);
                    }
                    break;
                default:
                    break;
            }
        });
    
    }

    return availablePages;
}

function PrivateRoute({ render: funct , userIsLoggedIn : userLoginState, onChangeLoginState: changeFunct, clientLogoutInterval: timeout , ...props }){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let loginMode = localStorage.getItem('loginMode');
    let pwdAlreadyChanged = JSON.parse(localStorage.getItem('pwdAlreadyChanged'));
    let pagePermission = true;
    let pageExisting = false;
    let passwortChangeRequested = false;
    let availablePages = [];

    if(userInfo){   
        
        availablePages = checkForAvailablePages(userInfo);

        switch (props.location.pathname) {
            case "/personalInfos":
            case "/bookings":
            case "/workOrderBookings":
            case "/requests":
                pagePermission = userInfo.WebClientRole.WebClientRolePermissions[RoutePermissionMap[props.location.pathname]];
                pageExisting= true;
                if(userInfo.LoginInformation.IsFirstCredentialsLogin && loginMode === "Credentials" && !pwdAlreadyChanged){
                    passwortChangeRequested = true;
                }
                break;
            case "/changePassword":
                passwortChangeRequested = true;
                break;
            default:
                break;
        }
        
    }

    // User logs in without initial password
    if(!passwortChangeRequested){
        return(
            <Route {...props} render={ (props) => (
                localStorage.getItem('userState') === "active" ?  
                // If the WebClient user has no permissions for the requested page, display the 403 page.
                !pagePermission ? <PageError 
                                    errorCode={403} 
                                    errorCodeDescr={"403_Forbidden"} 
                                    errorCodeDetail={"403_ForbiddenMsg"} 
                                    availablePages={availablePages} 
                                    userIsLoggedIn={userLoginState} 
                                    onChangeLoginState={changeFunct} 
                                    clientLogoutInterval={timeout} 
                                    {...props}/> : 
                                    // If the user has requested an unknown page, display page 404.
                                    !pageExisting ? <PageError 
                                                        errorCode={404} 
                                                        errorCodeDescr={"404_NotFound"} 
                                                        errorCodeDetail={"404_NotFoundMsg"} 
                                                        availablePages={availablePages} 
                                                        userIsLoggedIn={userLoginState} 
                                                        onChangeLoginState={changeFunct} 
                                                        clientLogoutInterval={timeout} 
                                                        {...props} />  :
                    // User has requested a known page, where he has the permissions to use it. 
                    // The requested page will be rendered.
                    funct(props) :  
                <Redirect to={{pathname:'/login', state: {from: props.location}}}/>
            )}/>
        )
    }
    else{ // User needs to change his initial password or requested a password change.
        if(props.location.pathname !== "/changePassword"){
            return(
                <Redirect to={{pathname:'/changePassword', state: {from: props.location}}}/>
            );
        }
        else{
            return(
                <Route path="/changePassword" name="Change Password" render={props => <PasswordChange userIsLoggedIn={userLoginState} onChangeLoginState={changeFunct} clientLogoutInterval={timeout} {...props} />} />
            )
        }
        
    }
    
}

function LoginRoute({ render: funct, ...props }){
    const lastPath = localStorage.getItem('componentPath');
    return(
        <Route {...props} render={ (props) => (
            (localStorage.getItem('userState') !== "active" || !localStorage.getItem('userState') )  ? funct(props) :  <Redirect to={lastPath ? lastPath: '/bookings'}/>
        )}/>
    )
}

export {
    RoutePermissionMap,
    PrivateRoute,
    LoginRoute,
    checkForAvailablePages,
    getAvailableRoutes
} 
