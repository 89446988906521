import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment/min/moment-with-locales';

i18n
  // https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    detection: {
      order: ['localStorage','navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    },
    ns: ['common','attendances','bookings','cwHours','lock','login','personalInfos','requests', 'table', 'workOrderBookings'],
    fallbackLng: 'de',
    debug: true,

    interpolation: {
      format: function(value, format, lng) {
        if(value instanceof Date) return moment(value).format(format);
        return value;
      },
      escapeValue: false, // not needed for react as it escapes by default

    },
  });

export default i18n;