import moment from 'moment/min/moment-with-locales';

function date_sort_asc(date1, date2) {
    if (date1 > date2) return 1;
    if (date1 < date2) return -1;
    return 0;
};
  
function date_sort_desc(date1, date2) {
    if (date1 > date2) return -1;
    if (date1 < date2) return 1;
    return 0;
};

function booking_sort_asc(book1, book2){
    if (moment(book1.TimeStamp).format('x') > moment(book2.TimeStamp).format('x')) return 1;
    if (moment(book1.TimeStamp).format('x') < moment(book2.TimeStamp).format('x')) return -1;
    return 0;
}

function booking_sort_desc(book1, book2){
    if (moment(book1.TimeStamp).format('x') > moment(book2.TimeStamp).format('x')) return -1;
    if (moment(book1.TimeStamp).format('x') < moment(book2.TimeStamp).format('x')) return 1;
    return 0;
}

function order_sort_asc(order1, order2){
    if (parseInt(order1.OrderNo) > parseInt(order2.OrderNo)) return 1;
    if (parseInt(order1.OrderNo) < parseInt(order2.OrderNo)) return -1;
    return 0;
}

function order_sort_desc(order1, order2){
    if (parseInt(order1.OrderNo) > parseInt(order2.OrderNo)) return -1;
    if (parseInt(order1.OrderNo) < parseInt(order2.OrderNo)) return 1;
    return 0;
}

function sequence_sort_asc(sequence1, sequence2){
    if (parseInt(sequence1.SequenceNo) > parseInt(sequence2.SequenceNo)) return 1;
    if (parseInt(sequence1.SequenceNo) < parseInt(sequence2.SequenceNo)) return -1;
    return 0;
}

function sequence_sort_desc(sequence1, sequence2){
    if (parseInt(sequence1.SequenceNo) > parseInt(sequence2.SequenceNo)) return -1;
    if (parseInt(sequence1.SequenceNo) < parseInt(sequence2.SequenceNo)) return 1;
    return 0;
}

function specification_sort_asc(spec1, spec2){
    if (parseInt(spec1.SpecificationNo) > parseInt(spec2.SpecificationNo)) return 1;
    if (parseInt(spec1.SpecificationNo) < parseInt(spec2.SpecificationNo)) return -1;
    return 0;
}

function specification_sort_desc(spec1, spec2){
    if (parseInt(spec1.SpecificationNo) > parseInt(spec2.SpecificationNo)) return -1;
    if (parseInt(spec1.SpecificationNo) < parseInt(spec2.SpecificationNo)) return 1;
    return 0;
}

function activity_sort_asc(act1, act2){
    if (parseInt(act1.ActivityCode) > parseInt(act2.ActivityCode)) return 1;
    if (parseInt(act1.ActivityCode) < parseInt(act2.ActivityCode)) return -1;
    return 0;
}

function activity_sort_desc(act1, act2){
    if (parseInt(act1.ActivityCode) > parseInt(act2.ActivityCode)) return -1;
    if (parseInt(act1.ActivityCode) < parseInt(act2.ActivityCode)) return 1;
    return 0;
}

function sort_alphabetical_asc(letter1, letter2){
    if (parseInt(letter1) > parseInt(letter2)) return 1;
    if (parseInt(letter1) < parseInt(letter2)) return -1;
    return 0;
}

function sort_alphabetical_desc(letter1, letter2){
    if (parseInt(letter1) > parseInt(letter2)) return -1;
    if (parseInt(letter1) < parseInt(letter2)) return 1;
    return 0;
}

function time_sort_asc(moment1, moment2){
    if (moment(moment1).format('x') > moment(moment2).format('x')) return 1;
    if (moment(moment1).format('x') < moment(moment2).format('x')) return -1;
    return 0;
}

function time_sort_desc(moment1, moment2){
    if (moment(moment1).format('x') > moment(moment2).format('x')) return -1;
    if (moment(moment1).format('x') < moment(moment2).format('x')) return 1;
    return 0;
}

function dynamicRequestSort(ascendingSorting, property){
    return function(req1, req2){
        var result = (req1[property] > req2[property]) ? 1 : (req1[property] < req2[property]) ? -1 : 0;
        return result * (ascendingSorting ? 1: -1);
    }
}




export {
    date_sort_asc,
    date_sort_desc,
    booking_sort_asc,
    booking_sort_desc,
    order_sort_asc,
    order_sort_desc,
    sequence_sort_asc,
    sequence_sort_desc,
    specification_sort_asc,
    specification_sort_desc,
    activity_sort_asc,
    activity_sort_desc,
    sort_alphabetical_asc,
    sort_alphabetical_desc,
    time_sort_asc,
    time_sort_desc,
    dynamicRequestSort
}
